$primaryColor: #1890ff;

body {
  margin: 0;
  font-family: 'Noto Sans JP', 'Roboto', sans-serif;
  color: #fff;
  font-size: 16px;
  overflow: auto;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
}

.application {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.w3a-parent-container #w3a-modal .w3a-button--login[title='Twitter'] {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-image: url('./../../resources/images/icon/icons-twitter.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
  }

  > img {
    visibility: hidden;
  }
}

.w3a-social__policy,
.text-right,
.w3ajs-external-wallet,
.w3a-modal__footer {
  display: none;
}

.w3ajs-social-logins {
  .w3a-button {
    > p {
      color: #111928;
    }
  }
  .w3a-button--primary {
    background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
    > .image-icon {
      filter: invert(1);
    }
    &:hover {
      background-color: rgb(209 213 219 / var(--tw-bg-opacity)) !important;
    }
  }
}

.w3a-parent-container #w3a-modal .w3a-header {
  > div {
    width: 100%;
    .w3a-header__title {
      text-align: center;
    }
    .w3a-header__subtitle {
      display: none;
    }

    .w3a-header__title {
      text-indent: -9999px;
      margin-top: -18px;
    }
    
    .w3a-header__title::after {
      content: "Sign Up";
      text-indent: 0;
      display: block;
    }
  }
}

.w3a-parent-container #w3a-modal .w3a-modal__inner.w3a-modal__inner--active {
  min-height: 300px;
  height: auto;
}

.w3a-parent-container #w3a-modal .w3a-sms-field--error {
  white-space: nowrap;
  overflow: hidden;
  margin-right: 235px;

  @media screen and (max-width: 768px) {
    margin-right: 202px;
  }
}

.title {
  max-width: 100%;
  word-wrap: break-word;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.custom-option {
  background-color: #051847;
  border: 1px solid #156df280;
  .ant-select-item-option-content {
    color: #fff;
  }

  .ant-select-item-option-selected {
    .ant-select-item-option-content {
      color: #000;
    }
  }

  .ant-select-item-option-active {
    .ant-select-item-option-content {
      color: #000;
    }
  }

  .ant-empty-description {
    color: #fff;
  }

  .rc-virtual-list-scrollbar-thumb {
    background: #fff !important;
  }
}

.date-picker-panel-custom {
  .ant-picker-panel-container {
    background-color: #051847;
  }

  .ant-picker-header,
  .ant-picker-header button,
  .ant-picker-cell-in-view,
  .ant-picker-content th {
    color: #fff !important;
  }

  .ant-picker-cell {
    color: #757b8c;
  }

  .ant-picker-cell-disabled {
    color: #393939 !important;
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
      .ant-picker-cell-range-start
    ):not(.ant-picker-cell-range-end):not(
      .ant-picker-cell-range-hover-start
    ):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background-color: #156df2;
  }
}

.ant-tooltip {
  &.ant-tooltip-placement-top {
    max-width: unset;
  }
}

/*===============  RESPONSIVE  ==================*/

@media (min-width: 320px) {
}

@media (min-width: 767.98px) {
}

@media (min-width: 1024px) {
}

.logo {
  cursor: pointer;

  img {
    width: 400px;
    filter: invert(1);
  }
}